import React, { useState, useEffect } from "react";

function TaskList() {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState("");

  useEffect(() => {
    fetch(`/api/tasks`)
      .then((response) => response.json())
      .then((data) => setTasks(data));
  }, [setTasks]);

  const addTask = () => {
    fetch(`/api/tasks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ description: newTask }),
    })
      .then((response) => {
        if (response.status === 201) {
          setNewTask("");
          // Reload the task list after adding a new task
          return fetch(`/api/tasks`);
        }
        throw new Error("Failed to add task");
      })
      .then((response) => response.json())
      .then((data) => setTasks(data))
      .catch((error) => console.error(error));
  };

  const markAsCompleted = (taskId) => {
    fetch(`/api/tasks/${taskId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 204) {
          // Reload the task list after marking a task as completed
          return fetch(`/api/tasks`);
        }
        throw new Error("Failed to mark task as completed");
      })
      .then((response) => response.json())
      .then((data) => setTasks(data))
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <h2>TODO List</h2>
      <ul>
        {tasks.map((task) => (
          <li key={task.id}>
              {task.title}
              {!task.completed && (<button onClick={() => markAsCompleted(task.id)}>Mark as Completed</button>)}
          </li>
        ))}
      </ul>
      <div>
        <input
          type="text"
          placeholder="New Task"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
        />
        <button onClick={addTask}>Add Task</button>
      </div>
    </div>
  );
}

export default TaskList;

